<div
  #itemsContainer
  class="tab-selector"
>
  <div
    [style.padding-inline.px]="inlinePadding"
    class="tab-selector__items"
  >
    @for (tab of tabs; track tab) {
      <div
        (click)="handleTabSelect(tab)"
        [attr.data-tab-id]="tab.id"
        [class.tab-selector__items-item--disabled]="tab.disabled"
        [class.tab-selector__items-item--selected]="selectedTabId === tab.id"
        class="tab-selector__items-item"
      >
        <div
          [attr.data-text]="tab.label"
          class="tab-selector__items-item-title"
        >
          {{ tab.label || tab.labelTranslationKey | translate }}
        </div>
      </div>
    }
  </div>

  <div class="tab-selector__line-container">
    <div
      [style.left.px]="lineParams.left"
      [style.width.px]="lineParams.width"
      class="tab-selector__line-container-line"
    ></div>
  </div>
</div>
