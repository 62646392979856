import {
  type AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  type OnChanges,
  output,
  type SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

interface LineParams {
  width: number;
  left: number;
}

export interface TabSelectorItem {
  id: string;
  label?: string;
  labelTranslationKey?: string;
  disabled: boolean;
}

@Component({
  selector: 'cc-tab-selector',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss'],
})
export class TabSelectorComponent implements AfterViewInit, OnChanges {
  @Input()
  tabs: TabSelectorItem[] = [];

  @Input()
  inlinePadding = 0;

  tabSelect = output<TabSelectorItem>();

  @ViewChild('itemsContainer', { static: true })
  itemsContainer: ElementRef;

  lineParams: LineParams = {
    width: 0,
    left: 0,
  };

  private readonly cdr = inject(ChangeDetectorRef);

  @Input('selectedTabId')
  _selectedTabId: string = null;

  get selectedTabId(): string {
    return this._selectedTabId || this.tabs[0]?.id;
  }

  ngAfterViewInit(): void {
    this.calculateLineParams();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedTabIdChange = changes['_selectedTabId'];

    if (selectedTabIdChange && selectedTabIdChange.currentValue !== selectedTabIdChange.previousValue) {
      this.calculateLineParams();
    }
  }

  handleTabSelect(tab: TabSelectorItem): void {
    this.tabSelect.emit(tab);
    this._selectedTabId = tab.id;
    this.calculateLineParams();
  }

  private calculateLineParams(): void {
    const activeTab = this.itemsContainer.nativeElement.querySelector(
      `.tab-selector__items-item[data-tab-id="${this.selectedTabId}"]`,
    );
    if (!activeTab) return;

    const width = activeTab.clientWidth;
    const tabLeft = activeTab.offsetLeft;
    const containerLeft = this.itemsContainer.nativeElement.offsetLeft;
    const left = tabLeft - containerLeft;

    this.lineParams = {
      width,
      left,
    };
    this.cdr.detectChanges();
  }
}
